/* App.css */

/* General Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f9fafb;
  color: #333;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

h1, h2, h3 {
  font-weight: 700;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Navbar Styles */
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #007bff;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.nav-links a:hover {
  color: #007bff;
}

/* Hero Section */
.hero {
  background-image: url("./assets/images/hero-image.png");
  background-size: cover;
  background-position: center;
  padding: 150px 0 100px 0;
  color: #fff;
  text-align: center;
  position: relative;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Features Section */
.features {
  padding: 80px 0;
  background-color: #f0f2f5;
}

.features h2 {
  text-align: center;
  margin-bottom: 60px;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.feature-item {
  width: 45%;
  margin-bottom: 40px;
  text-align: center;
}

.feature-item i {
  color: #007bff;
  margin-bottom: 20px;
}

.feature-item h3 {
  margin-bottom: 15px;
}

/* How It Works Section */
.how-it-works {
  padding: 80px 0;
}

.how-it-works h2 {
  text-align: center;
  margin-bottom: 60px;
}

.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.step {
  width: 30%;
  margin-bottom: 40px;
  text-align: center;
}

.step-number {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  color: #fff;
  font-size: 2rem;
  line-height: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.step h3 {
  margin-bottom: 15px;
}

/* About Us Section */
.about-us {
  padding: 80px 0;
  background-color: #f0f2f5;
}

.about-us h2 {
  text-align: center;
  margin-bottom: 40px;
}

.about-us p {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

/* Contact Us Section */
.contact-us {
  padding: 80px 0;
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 40px;
}

.contact-us p {
  text-align: center;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 30px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-container p {
  margin: 0;
}

.social-icons a {
  color: #fff;
  margin-left: 15px;
  font-size: 1.2rem;
}

.social-icons a:hover {
  color: #007bff;
}

/* Room Component Styles */
.video {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.list-group-item {
  font-size: 1rem;
}
.input-group {
  max-width: 500px;
  margin: 0 auto;
}

.input-group .form-control {
  border-right: none;
}

.input-group .btn {
  border-left: none;
}

.input-group .btn:focus, .input-group .form-control:focus {
  box-shadow: none;
}

/* Media Queries */
@media (max-width: 768px) {
  .features-grid, .steps {
    flex-direction: column;
    align-items: center;
  }

  .feature-item, .step {
    width: 80%;
  }

  .nav-links {
    display: none;
  }

  .navbar {
    justify-content: center;
  }

  .hero {
    padding: 120px 0 80px 0;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }
}
