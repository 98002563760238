/* CopyLink.css */

.container-style {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
}

.input-group-style {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.input-style {
    border-radius: 4px;
}

.label-style {
    font-weight: bold;
    margin-bottom: 8px;
}

.button-style {
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
}

.copy-button-style {
    margin-left: 10px;
}
